<template>
  <div class="loading">
    <img
      src="https://static.5t1800.com/s3/2023/05/16/6463359a8b805.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
img {
  display: inline-block;
  width: 30px;
  height: 30px;
  animation: turn 1s linear infinite;
}

@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>

<template>
  <div style="background: #f7f7fb">
    <div id="data-loaded-marker" v-if="dataLoaded"></div>
    <!-- pc -->
    <div class="textBox hidden-xs-only">
      <Loading v-show="loading"></Loading>
      <div class="textInfo">
        <div class="header">
          <div class="title">
            {{ textInfo.title }}
          </div>
          <div class="remInfo">
            发布者：{{ textInfo.author }} | {{ textInfo.update_at }}
          </div>
        </div>
        <div v-html="textInfo.content" style="content"></div>
      </div>
    </div>
    <!-- h5 -->
    <div class="textBoxH5 hidden-sm-and-up">
      <Loading v-show="loading"></Loading>
      <div class="textInfo">
        <div class="header"></div>
        <div class="title">
          {{ textInfo.title }}
        </div>
        <div class="remInfo">
          发布者：{{ textInfo.author }} | {{ textInfo.update_at }}
        </div>
      </div>
      <div class="content" v-html="textInfo.content"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../components/loading.vue";
export default {
  data() {
    return {
      textInfo: {}, //文章信息
      loading: false, //请求加载
      dataLoaded: false,
    };
  },
  metaInfo: {
    title: "Vinistyle 薇妮", // set a title
    meta: [
      {
        name: "keyWords",
        content:
          "薇妮vinistyle,薇妮,薇妮美肤,薇尼美肤空间,护肤品,化妆品加盟,薇妮化妆品加盟,化妆品,薇妮化妆品,美白,防晒,防晒霜",
      },
      {
        name: "description",
        content:
          "vinistyle薇妮是江苏苏美国际旗下化妆品品牌，主营功效性护肤产品和解决方案，包括美容院专业线套组以及居家护肤产品，针对用户常见的斑、痘、敏、皱四大类肌肤问题，都有针对性的产品和解决方案。vinistyle薇妮提供整店输出、产品合作、项目合作等各种合作方式，诚邀美容院、皮肤管理工作、化妆品实体店加盟合作，共创美丽事业！",
      },
    ],
  },
  components: {
    Loading,
  },
  created() {
    this.$store.commit("saveTypeIndex", 5);
    this.id = JSON.parse(this.$route.params.id);
  },
  async mounted() {
    try {
      const response = await this.getSortTxtList(this.id);
      console.log(response);
      // document.dispatchEvent(new Event("render-event"));
      this.dataLoaded = true;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    //获取分类下的所有文章
    getSortTxtList(id, pageSize = 5, pageNumber = 1, is_hot = 0) {
      let _self = this;
      _self.loading = true;
      axios({
        method: "get",
        url: `/api/news/getNewsInformation`,
        params: {
          id: id,
          pageSize,
          pageNumber,
          is_hot,
        },
      })
        .then((res) => {
          _self.loading = false;
          _self.textInfo = res.data.rows[0];
          console.log(_self.textInfo);
          this.dataLoaded = true;
        })
        .catch((err) => {
          _self.loading = false;
          console.log(err);
          this.dataLoaded = true;
        });
    },
  },
};
</script>

<style scoped>
.textBox {
  padding: 20px 0;
}
.textInfo {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 9px -3px rgb(10 10 10 / 3%);
  padding: 20px;
}
.textInfo .header .title {
  line-height: 35px;
  font-size: 24px;
  color: #18191a;
  font-weight: 700;
}
.textInfo .header .remInfo {
  font-size: 13px;
  color: #999;
  padding: 10px 0;
  border-bottom: 1px solid #f7f7fb;
}
.textInfo .content img {
  max-width: 100%;
}

.textBoxH5 {
  padding: 8px;
}
.textBoxH5 .textInfo {
  margin: 0 auto;
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 9px -3px rgb(10 10 10 / 3%);
  padding: 8px;
}
.textBoxH5 .textInfo .header .title {
  line-height: 35px;
  font-size: 20px;
  color: #18191a;
  font-weight: 700;
}
.textBoxH5 .textInfo .header .remInfo {
  font-size: 13px;
  color: #999;
  padding: 10px 0;
  border-bottom: 1px solid #f7f7fb;
}

.textBoxH5 .content >>> img {
  width: 100% !important;
}
</style>

